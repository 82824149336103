<template>
	<div>
		<!--   岗位设置 搜索-->
		<div class="doping-top child-top">
			<div class="input-from">
				<!-- <div style="float: right;">
                    <el-input
                            class="input-with-select"
                            clearable
                            placeholder="请输入关键字"
                            style="width: 340px;"
                            v-model="input"
                    >
                        <el-select
                                @change="currentSel"
                                clearable
                                placeholder="请选择"
                                slot="prepend"
                                style="width:100px;"
                                v-model="select"
                        >
                            <el-option label="名称" value="name"></el-option>
                            <el-option label="档案类型" value="archive_name"></el-option>
                        </el-select>
                        <el-button
                                @click="currentPage = 1;sesarchFun()"
                                @keyup.enter.native="sesarchFun"
                                icon="iconfont iconsousuo"
                                slot="append"
                                style="text-align: center;width: 50px;color: #fff"
                        ></el-button>

                    </el-input>
                </div> -->

				<el-input @keyup.enter.native="sesarchFun(true)" clearable placeholder="请输入关键字" style="width: 200px" suffix-icon="iconfont iconsousuo" v-model="input"> </el-input>
				<el-button class="searchBtn" @click="sesarchFun(true)" slot="append" round>搜索</el-button>
			</div>
			<div class="operation-button">
				<el-button @click="postAdd()" class="new_btn" :disabled="$store.getters.permissionsStr('新增')" round>
					<span>新增</span>
				</el-button>
				<el-button @click="deleDialog()" class="del_btn" round :disabled="$store.getters.permissionsStr('删除')">
					<span>删除</span>
				</el-button>
			</div>
		</div>

		<!--    表格-->
		<div>
			<el-table :data="tableData" @selection-change="toggleRowSelection" class="content-test-table" style="width: 100%" v-loading="tableLoading">
				<el-table-column show-overflow-tooltip type="selection" width="50"></el-table-column>

				<el-table-column label="序号" show-overflow-tooltip type="index" width="50">
					<template slot-scope="scope">
						{{ (currentPage - 1) * 10 + scope.$index + 1 }}
					</template>
				</el-table-column>

				<el-table-column label="名称" prop="name" show-overflow-tooltip></el-table-column>

				<!-- <el-table-column label="档案类型" prop="archive_name" show-overflow-tooltip></el-table-column> -->

				<el-table-column label="所含人数" prop="quantity" show-overflow-tooltip></el-table-column>

				<el-table-column align="left" label="备注" prop="demo" show-overflow-tooltip></el-table-column>

				<el-table-column label="状态" prop="status" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-switch
							active-color="#0055E9"
							:active-value="1"
							:disabled="$store.getters.permissionsStr('启/停用')"
							:inactive-value="0"
							:width="35"
							@change="ChangeState(scope.row)"
							inactive-color="#ccc"
							v-model="scope.row.status"
						></el-switch>
					</template>
				</el-table-column>

				<el-table-column label="操作" width="130">
					<template slot-scope="scope">
						<el-button
							:class="!(scope.row.is_sport === 0 && scope.row.is_quote === 0) ? 'disabledColor' : ''"
							:disabled="!(scope.row.is_sport === 0 && scope.row.is_quote === 0) || $store.getters.permissionsStr('编辑')"
							@click="handleEdit(scope.$index, scope.row)"
							class="blueColor right20"
							size="medium"
							type="text"
							>编辑
						</el-button>
						<!-- v-show="!$store.getters.permissionsStr('编辑')" -->
						<!-- <el-button
                                @click="newSub(scope.row)"
                                class="greenColor right20"
                                size="medium"
                                type="text"
                        >选择档案
                        </el-button> -->
						<!-- v-show="!$store.getters.permissionsStr('选择档案')" -->
						<el-button
							:class="!(scope.row.is_quote === 0 && scope.row.is_sport === 0) ? 'disabledColor' : ''"
							:disabled="!(scope.row.is_quote === 0 && scope.row.is_sport === 0) || $store.getters.permissionsStr('编辑') || $store.getters.permissionsStr('删除')"
							@click="deleTable(scope.$index, scope.row)"
							class="redColor right20"
							size="medium"
							type="text"
							>删除
						</el-button>
						<!-- v-show="!$store.getters.permissionsStr('删除')" -->
					</template>
				</el-table-column>
			</el-table>
			<!--            页码-->
			<el-pagination
				:current-page="currentPage"
				:total="totalData"
				@current-change="handleCurrentChange"
				@size-change="handleSizeChange"
				class="table-pagination"
				layout="total, jumper,  ->, prev, pager, next"
			></el-pagination>
		</div>

		<!--    新增对话框-->
		<el-dialog :close-on-click-modal="false" :title="addMenuTitle" :visible.sync="dialogVisible" @close="$refs['ruleForm'].resetFields()" center top="10vh" width="550px">
			<!--      新增详情-->
			<el-form :model="ruleForm" :rules="rules" label-position="left" label-width="60px" ref="ruleForm" style="margin-bottom: 20px">
				<el-form-item label="名称" prop="name" style="margin-bottom: 20px">
					<!--                    新增名称-->
					<el-input placeholder="请输入名称" v-if="isEditDialog" v-model="ruleForm.name"></el-input>
					<!--                    编辑名称-->
					<el-input :disabled="quantity > 0 ? true : false" v-if="!isEditDialog" v-model="ruleForm.name"></el-input>
				</el-form-item>
				<!--                新增编辑备注-->
				<el-form-item label="备注" prop="demo">
					<el-input :rows="3" placeholder="请输入内容" type="textarea" v-model="ruleForm.demo"></el-input>
				</el-form-item>
			</el-form>
			<div class="clearfix"></div>
			<div class="form-bottom-button clearfix">
				<!-- 新增的保存-->
				<el-button @click="newDocument('ruleForm')" type="primary" v-if="isEditDialog" v-no-more-click class="save_btn" round>保 存 </el-button>

				<!-- 编辑的 保存-->
				<el-button @click="redactDocument('ruleForm')" type="primary" v-if="!isEditDialog" v-no-more-click class="save_btn" round>保 存 </el-button>

				<el-button @click="menuResetFormFun('ruleForm')" class="cancel_btn" round>取 消</el-button>
			</div>
		</el-dialog>

		<!--        插件穿梭框-->
		<!-- <div class="newsub"> -->
		<!--        插件穿梭框-->
		<el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogFormVisible" center class="shuttleDialog" width="50%">
			<!-- <div class="dialogBox" style="border:1px solid #f6f6f6;">
                    <el-tree
                            :current-node-key="currentNodeKey"
                            :data="fromData"
                            :default-checked-keys="defaultCheckedKeys"
                            :default-expanded-keys="defaultExpandedKeys"
                            :props="{
                            label: 'name',
                            children: 'children'
                        }"
                            class="roleTree"
                            node-key="uuid"
                            ref="logTree"
                            show-checkbox
                    ></el-tree>
                </div> -->
			<div class="ModifyDoalog">
				<div class="newTable">
					<div style="width: 45%; float: left; border: 1px solid #ccc">
						<span style="margin: 0px 10px 0 20px"> 选择档案 </span>
						<el-input
							class="left-child-input left-child-input-content"
							clearable
							placeholder="请输入关键字"
							style="width: 200px; margin-bottom: 15px; margin-top: 10px"
							suffix-icon="iconfont iconsousuo"
							v-model="input4"
							@keyup.enter.native="accounSous({ uuid: getuuid })"
						>
						</el-input>
						<el-table
							:data="fromData"
							filter-multiple="true"
							@select="rowChange"
							@select-all="rowChangeAll"
							class="content-test-table new_table"
							v-loading="addtableLoading"
							style="width: 100%"
							ref="dataTable"
							height="250"
							:header-cell-style="{ background: '#F4F6F9' }"
						>
							<el-table-column type="selection" width="45"></el-table-column>
							<el-table-column label="序号" type="index" width="45">
								<template slot-scope="scope">
									{{ (currentPage - 1) * 10 + scope.$index + 1 }}
								</template>
							</el-table-column>
							<el-table-column label="名称" prop="name" width="230" show-overflow-tooltip></el-table-column>
						</el-table>
					</div>

					<div style="width: 50%; marin-left: 50px; float: right; border: 1px solid #ccc">
						<span style="display: block; margin: 15px 10px 28px 20px"> 已选档案 {{ this.selectTableData.length }}/{{ this.num }} </span>
						<el-table
							:data="selectTableData"
							class="content-test-table new_table"
							style="width: 100%"
							height="250"
							v-loading="addtableLoading"
							:header-cell-style="{ background: '#F4F6F9' }"
						>
							<el-table-column label="序号" type="index" width="45">
								<template slot-scope="scope">
									{{ (currentPage - 1) * 10 + scope.$index + 1 }}
								</template>
							</el-table-column>
							<el-table-column label="名称" width="260" prop="name" show-overflow-tooltip></el-table-column>
							<el-table-column width="50">
								<template slot-scope="scope">
									<i class="iconfont iconshanchu-moren" @click="selectClick(scope.$index)"></i>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>

			<div class="form-bottom-button">
				<el-button @click="suretransfer" type="primary" v-no-more-click class="save_btn" round>保 存</el-button>
				<el-button
					@click="
						() => {
							dialogFormVisible = false
							selectTableData = []
						}
					"
					class="cancel_btn"
					round
					>取 消</el-button
				>
			</div>
		</el-dialog>
		<!-- </div> -->
	</div>
</template>

<script>
let tableData, totalData

//表格的查询渲染
function tableDataRenderFun(vm) {
	let data = {}
	if (vm.selVal == 'name') {
		data = {
			name: vm.input,
			page: vm.currentPage,
		}
	} else if (vm.selVal == 'archive_name') {
		data = {
			archive_name: vm.input,
			page: vm.currentPage,
		}
	} else {
		data = {
			page: vm.currentPage,
		}
	}
	vm.tableLoading = true
	vm.$axios
		.post('/p/station/lists', vm.$qs(data))
		.then(response => {
			vm.tableLoading = false
			if (response.data.code === 0) {
				vm.totalData = response.data.data.total
				vm.tableData = response.data.data.rows
			} else {
				vm.$message({
					message: response.message,
					type: 'error',
				})
			}
		})
		.catch(function(error) {
			console.log(error)
		})
}

export default {
	data() {
		return {
			is_quote: '', //是否引用
			quantity: '', // 所含人数
			defaultExpandedKeys: [], //默认展开的节点的 key 的数组
			defaultCheckedKeys: [], //默认勾选的节点的 key 的数组
			currentNodeKey: '', //当前选中的节点
			getuuid: [], //点击当前表格UUid
			subUuid: [], //穿梭框选中uuid
			operatinguuid: [], //正在操作的某一行的id（删除功能使用）
			currentPage: 1, //页码
			dialogVisible: false, //  新增岗位档案弹窗
			addMenuTitle: '新增岗位档案',
			isEditDialog: false, //岗位档案弹窗是否为编辑状态  false 是编辑 true 是新增
			ruleForm: {
				// 新增、编辑岗位档案中表单
				name: '',
				demo: '',
			},
			rules: {
				name: { ...this.$rules.noEmpty, message: '名称不能为空' },
			},
			totalData: totalData, //总页数
			tableData: tableData, //表格中的数据
			value: [1],
			value4: [1],
			select: '',

			input: '', //表头搜索

			input1: '', //新增 名称

			input2: '', //编辑名称

			textarea: '', //新增备注

			Redact: false, //  选择档案

			tableLoading: false, //表格 loading,

			deleteDisabled: true, //批量删除按钮启用与禁用

			dialogFormVisible: false, //选择档案

			//    插件穿梭框数据
			title: '选择档案',
			mode: 'transfer', // transfer addressList

			toData: [], //提交穿梭框的数据
			fromData: [], //    获取到的穿梭框数据
			selectUuid: [], //选择档案的下标  取得uuid
			checkUuid: [], //选中档案的下标  取得uuid
			selVal: '', //搜索框选中值
			status: '', //启动状态的值
			selectTableData: [],
			addtableLoading: false,
			input4: '',
			num: 0,
			newData: [],
		}
	},

	mounted() {
		this.$nextTick(() => {
			this.$refs.dataTable
		})
	},

	created() {
		//页面加载获取表格数据
		tableDataRenderFun(this)

		/*
		 * @name：reverse
		 * @author：liuxin
		 * @date：2020-04-13
		 * @功能：字符串翻转
		 * @输入参数：str 字符串 'hello.world'
		 * @输入参数：divide 字符串 需要分隔的符号  '.'
		 * @返回值：类型(String) 字符串
		 * @字符串翻转 es6写法： string.split('.').reverse().join('.')
		 * */
		// function reverse(str, divide) {
		//     let keep = '';
		//     let temp = '';
		//     for (let i = 0; i < str.length; i++) {
		//         if (str[i] === divide) {
		//             keep = divide + temp + keep;
		//             temp = ''
		//         } else {
		//             temp += str[i];
		//         }
		//     }
		//     return keep = temp + keep
		// }
		// console.log(reverse('wo.shi.ni', '.'));
	},
	methods: {
		// 选择档案弹窗
		newSub(row) {
			this.addtableLoading = true
			this.defaultExpandedKeys = []
			this.defaultCheckedKeys = []
			this.getuuid = row.uuid
			this.$axios
				.post('/p/archive/show', this.$qs({ station_uuid: row.uuid }))
				.then(response => {
					if (response.data.code === 0) {
						if (this.input4 == '') {
							this.num = response.data.data.length
						}
						this.fromData = response.data.data
						setTimeout(() => {
							this.$nextTick(() => {
								// this.fromData.forEach((item) => {
								//   this.selectTableData.forEach((itm, idx) => {
								//     if(itm.uuid == item.uuid) {
								//       this.$refs.dataTable.toggleRowSelection(item, true);
								//     }
								//   })
								// });

								this.fromData.forEach(item => {
									if (item.is_check) {
										this.selectTableData.push(item)
										this.$refs.dataTable.toggleRowSelection(item, true)
									}
								})
							})
						}, 100)
					} else {
						this.$message({
							message: response.data.message,
							type: 'error',
						})
					}
					this.addtableLoading = false
				})
				.catch(error => {
					this.addtableLoading = false
					console.log(error)
				})
				.finally(() => {
					this.addtableLoading = false
					this.type = 'create'
					this.dialogFormVisible = true
					setTimeout(() => {
						this.$dialogHeight()
					}, 0)
				})
		},
		handleCurrentChange(val) {
			this.currentPage = val
			this.getList({ searchVal: this.searchval, page: this.currentPage })
		},
		// 账户搜索
		accounSous(row) {
			this.$axios
				.post('/p/archive/show', this.$qs({ station_uuid: row.uuid, name: this.input4 }))
				.then(res => {
					this.fromData = []
					if (res.data.code == 0) {
						this.fromData = res.data.data
						this.$nextTick(() => {
							this.fromData.forEach(item => {
								this.selectTableData.forEach((itm, idx) => {
									if (itm.uuid == item.uuid) {
										this.$refs.dataTable.toggleRowSelection(item, true)
									}
								})
							})
						})
					} else {
						this.$utils.message({
							type: 'error',
							message: res.data.message,
						})
					}
					this.addtableLoading = false
				})
				.catch(error => {})
		},

		rowChange(selection, row) {
			var typeLink = false,
				idx = -1
			this.selectTableData &&
				this.selectTableData.forEach((item, index) => {
					if (item.uuid == row.uuid) {
						typeLink = true
						idx = index
					}
				})

			setTimeout(() => {
				if (!typeLink) {
					this.selectTableData.push(row)
				} else {
					this.selectTableData.splice(idx, 1)
				}
			}, 100)
		},

		rowChangeAll(selection) {
			if (selection.length) {
				selection.forEach(item => {
					let index = JSON.stringify(this.selectTableData).indexOf(JSON.stringify(item))
					if (index == -1) {
						this.selectTableData.push(item)
					}
				})
			} else {
				this.selectTableData = []
			}
		},

		selectClick(index) {
			this.$nextTick(() => {
				var clear = this.selectTableData.splice(index, 1)[0]
				this.fromData.forEach((item, index) => {
					if (item.uuid == clear.uuid) {
						this.$refs.dataTable.toggleRowSelection(item, false)
					}
				})
			})
		},

		//选择档案提交
		suretransfer() {
			//穿梭框确定
			this.dialogFormVisible = false
			let uuids = ''
			this.selectTableData.forEach((item, index) => {
				if (index == 0) {
					uuids = item.uuid
				} else {
					uuids += ',' + item.uuid
				}
			})
			this.$axios
				.post(
					'/p/station/addArchive',
					this.$qs({
						uuid: this.getuuid,
						archive_uuid: uuids,
					})
				)
				.then(response => {
					if (response.data.code == 0) {
						this.selectTableData = []
						tableDataRenderFun(this)
						this.$message({
							message: response.data.message,
							type: 'success',
						})
					} else {
						this.$message({
							message: response.data.message,
							type: 'error',
						})
					}

					this.newSub()
				})
				.catch(error => {})
				.finally(() => {
					// always executed
					this.getuuid = ''
					this.subUuid = ''
					tableDataRenderFun(this)
				})
		},

		newTableSelectionChange(val) {
			this.selectTableData = val
			// val.forEach((item, index) => {
			//   this.fromData.forEach((itm, idx) => {
			//     if(item.uuid == itm.uuid) {
			//       itm.is_check = !itm.is_check
			//     }
			//   })
			// })
			// val.forEach((item, index) => {
			//   var type = true
			//   this.selectTableData.forEach((itm, idx) => {
			//     if(item.uuid == itm.uuid) type= false
			//   })
			//   if(type) {
			//     this.selectTableData.push(item)
			//   }
			// })
		},

		//新增表单弹窗
		postAdd() {
			this.dialogVisible = true
			this.addMenuTitle = '新增岗位档案'
			this.isEditDialog = true
		},

		//新增岗位档案提交
		newDocument(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					if (!this.$sishiLength(this.ruleForm.name)) {
						return this.$message({
							type: 'error',
							message: '岗位名称最大长度为40',
						})
					}
					if (!this.$bashiLength(this.ruleForm.demo)) {
						return this.$message({
							type: 'error',
							message: '备注最大长度为80',
						})
					}

					this.$axios
						.post(
							'/p/station/add',
							this.$qs({
								name: this.ruleForm.name,
								demo: this.ruleForm.demo,
							})
						)
						.then(response => {
							if (response.data.code === 0) {
								this.menuResetFormFun(formName)
								this.$message({
									message: response.data.message,
									type: 'success',
								})
								this.currentPage = 1
								tableDataRenderFun(this)
								this.tableLoading = false
							} else {
								this.$message({
									message: response.data.message,
									type: 'error',
								})
							}
						})
						.catch(function(error) {
							console.log(error)
						})
				} else {
					console.log('提交失败!')
					return false
				}
			})
		},

		//编辑表单弹窗
		handleEdit(index, row) {
			this.quantity = row.quantity
			this.dialogVisible = true
			this.addMenuTitle = '编辑岗位档案'
			this.isEditDialog = false
			this.$axios
				.post(
					'/p/station/info',
					this.$qs({
						uuid: row.uuid,
					})
				)
				.then(response => {
					if (response.data.code === 0) {
						this.ruleForm = response.data.data
						this.ruleForm.level = response.data.data.uuid
						this.$dialogHeight()
					} else {
						this.$message({
							message: response.data.message,
							type: 'error',
						})
					}
				})
				.catch(function(error) {
					console.log(error)
				})
		},

		//编辑岗位档案的提交
		redactDocument(formName) {
			//表单验证
			this.$refs[formName].validate(valid => {
				if (valid) {
					if (!this.$sishiLength(this.ruleForm.name)) {
						return this.$message({
							type: 'error',
							message: '岗位名称最大长度为40',
						})
					}

					if (!this.$bashiLength(this.ruleForm.demo)) {
						return this.$message({
							type: 'error',
							message: '备注最大长度为80',
						})
					}
					this.$axios
						.post(
							'/p/station/update',
							this.$qs({
								uuid: this.ruleForm.uuid,
								name: this.ruleForm.name,
								demo: this.ruleForm.demo,
							})
						)
						.then(response => {
							if (response.data.code === 0) {
								this.menuResetFormFun(formName)
								this.$message({
									message: response.data.message,
									type: 'success',
								})
								//编辑成功之后重新加载数据
								tableDataRenderFun(this)
								this.tableLoading = false
							} else {
								this.$message({
									message: response.data.message,
									type: 'error',
								})
							}
						})
						.catch(function(error) {
							console.log(error)
						})
				} else {
					console.log('提交失败！')
					return false
				}
			})
		},

		// 新增、编辑岗位档案的表单重置
		menuResetFormFun(formName) {
			this.dialogVisible = false
			this.$refs[formName].resetFields()
			this.ruleForm = {
				name: '',
				demo: '',
			}
			this.selectTableData = []
		},

		handlesearch() {
			var data = []
			return this.fromData.filter(item => {
				// filter()对象遍历,true 返回对象参数值,如果多条数据,自动使用数组拼接
				if (item.name.includes(this.input4)) {
					return item
				}
			})
		},

		//删除弹框
		deleDialog() {
			this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				closeOnClickModal: false,
				cancelButtonClass: '',
				center: true,
			}).then(() => {
				let uuid = this.operatinguuid.length > 0 ? this.operatinguuid.join(',') : this.operatinguuid
				this.$axios
					.post(
						'/p/station/delete',
						this.$qs({
							uuid: uuid,
						})
					)
					.then(response => {
						this.operatinguuid = []
						if (response.data.code === 0) {
							// 解决跳页后删除本页最后一条数据查询不跳转问题
							if (this.currentPage * 10 - 9 === this.total) {
								this.currentPage -= 1
							}
							if (this.currentPage === 0) {
								this.currentPage = 1
							}
							this.$message({
								message: response.data.message,
								type: 'success',
							})
						} else {
							this.$message({
								message: response.data.message,
								type: 'error',
							})
						}
						this.tableLoading = false
						tableDataRenderFun(this)
					})
					.catch(error => {
						this.operatinguuid = []
						console.log(error)
					})
			})
		},

		/*表格单行删除*/
		deleTable(index, row) {
			this.operatinguuid = []
			this.operatinguuid.push(row.uuid)
			this.deleDialog()
		},

		//复选框选中与取消
		toggleRowSelection(row) {
			if (row.length > 0) {
				this.deleteDisabled = false
				this.operatinguuid = row.map(value => {
					return value.uuid
				})
			} else {
				this.deleteDisabled = true
			}
		},

		//获取到搜索框里的值
		currentSel(selVal) {
			this.selVal = selVal
		},
		//搜索框
		sesarchFun() {
			this.tableLoading = true
			this.currentPage = 1
			let data = {
				page: this.currentPage,
				keyword: this.input,
			}
			// if (this.selVal == "name") {
			//     console.log(1);

			//     data = {
			//         name: this.input,
			//         page: this.currentPage
			//     };
			// } else if (this.selVal == "archive_name") {
			//     data = {
			//         archive_name: this.input,
			//         page: this.currentPage
			//     };
			// } else {
			//     data = {
			//         page: this.currentPage
			//     };
			// }
			this.$axios
				.post('/p/station/lists', this.$qs(data))
				.then(response => {
					this.tableLoading = false
					if (response.data.code === 0) {
						this.tableData = response.data.data.rows
						this.totalData = response.data.data.total
					} else {
						this.$message({
							message: response.data.message,
							type: 'error',
						})
					}
				})
				.catch(function(error) {
					console.log(error)
				})
		},

		//改变状态
		ChangeState(row) {
			this.$axios
				.post(
					'/p/station/able',
					this.$qs({
						uuid: row.uuid,
					})
				)
				.then(response => {
					if (response.data.code === 0) {
						this.status = row.status
						this.$message({
							message: response.data.message,
							type: 'success',
						})
						tableDataRenderFun(this)
					} else {
						this.$message({
							message: response.data.message,
							type: 'error',
						})
					}
				})
		},

		//  页码
		handleSizeChange(val) {
			tableDataRenderFun(this)
			this.tableLoading = true
		},
		handleCurrentChange(val) {
			this.currentPage = val
			tableDataRenderFun(this)
			this.tableLoading = true
		},

		//    插件穿梭框的方法
		//     点击选择档案
		selectVisible(row) {
			this.selectUuid = row.uuid
			this.dialogFormVisible = true

			this.$axios
				.post(
					'/p/archive/show',
					this.$qs({
						station_uuid: row.uuid,
					})
				)
				.then(response => {
					if (response.data.code === 0) {
						var fromData = []
						var fromDatas = {
							id: 1,
							pid: 0,
							label: '全部',
							children: [],
						}
						var archive = response.data.data
						archive.forEach(function(v, i) {
							v.pid = 1
							v.label = v.name
							v.children = []
							fromDatas['children'][i] = v
						})
						fromData.push(fromDatas)
						this.fromData = fromData
						// return a ;
					} else {
						this.$message({
							message: response.data.message,
							type: 'error',
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
			this.row = row
		},

		//监听选中状态
		checked(nodeObj) {
			this.checkUuid = nodeObj.uuid
		},

		//提交穿梭框
		subFile() {
			this.dialogFormVisible = false
			this.$axios
				.post(
					'/p/station/addArchive',
					this.$qs({
						uuid: this.selectUuid,
						archive_uuid: this.checkUuid,
					})
				)
				.then(response => {
					if (response.data.code === 0) {
						this.$message({
							message: response.data.message,
							type: 'success',
						})
					} else {
						this.$message({
							message: response.data.message,
							type: 'error',
						})
					}
				})
				.catch(function(error) {
					console.log(error)
				})
		},
	},

	// components: {treeTransfer}, // 注册
	watch: {
		filterText(val) {
			this.$refs.tree.filter(val)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/cssFunction.scss';
@import '../../assets/private_yuan_public.scss';

.child-top {
	.input-from {
		.searchBtn {
			margin-top: 10px;
		}
	}
	.operation-button {
		// float: left;
		margin-bottom: 10px;
		margin-top: 10px;
	}
}

.doping-top {
	.input-from {
		float: left;
		margin: 0 40px 0px 0;

		/deep/ .el-input-group__prepend {
			background: #fff;
		}

		.el-date-editor {
			float: left;
		}
	}

	.operation-button {
	}
}

.transfer {
	height: 300px !important;
}

ul li {
	padding-left: 20px;
}

.ModifyDoalog {
	height: 400px;
	overflow: auto;
}

.iconshanchu-moren {
	&:hover {
		color: #f00;
		cursor: pointer;
	}
}

.el-pagination {
	background: #fff;
	padding: 10px;
	margin-top: 0;
}
</style>
